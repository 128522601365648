import { CardActions, Divider } from '@mui/material'

const RsCardFooterAction = ({ children, cardActionsSx }) => (
  <>
    <Divider />
    <CardActions sx={cardActionsSx}>{children}</CardActions>
  </>
)

export default RsCardFooterAction
