import { relayStylePagination } from '@apollo/client/utilities'

// Common Cache Policies for the main Graph
const cachePolicies = {
  possibleTypes: {
    Location: ['City', 'Neighborhood', 'PublicSchool', 'Zipcode'],
    Attachment: ['Property', 'PremarketListing', 'ReverseProspectingRequest'],
  },
  typePolicies: {
    Property: {
      fields: {
        display_rules: {
          merge: true,
        },
        open_houses: {
          merge: true,
        },
      },
    },
    ConversationMessage: {
      fields: {
        attachments: relayStylePagination(['first']),
      },
    },
    Agent: {
      fields: {
        homebuyer_actions: {
          keyArgs: ['filters'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
        },
        hva_actions: {
          keyArgs: ['filters'],
          merge(existing = [], incoming) {
            return [...existing, ...incoming]
          },
        },
        nurtured_users_with_metrics: relayStylePagination(['type', '@connection', ['key']]),
        undesignated_users_with_metrics: relayStylePagination(['type', '@connection', ['key']]),
        archived_users_with_metrics: relayStylePagination(['type', '@connection', ['key']]),
        active_homebuyer_saved_searches: relayStylePagination(['type', '@connection', ['key']]),
        disabled_homebuyer_saved_searches: relayStylePagination(['type', '@connection', ['key']]),
        active_market_alerts: relayStylePagination(['type', '@connection', ['key']]),
        disabled_market_alerts: relayStylePagination(['type', '@connection', ['key']]),
        active_homebuyers: relayStylePagination(['type', '@connection', ['key']]),
        active_home_reports_connection: relayStylePagination(),
        disabled_home_reports_connection: relayStylePagination(),
        market_alert_templates: relayStylePagination(),
        saved_search_templates: relayStylePagination(),
      },
    },
    AdminAgent: {
      fields: {
        internal_tags_as_objects: {
          merge(existing = [], incoming) {
            return incoming
          },
        },
        users: relayStylePagination(),
      },
    },
    Manager: {
      fields: {
        agents: {
          merge: relayStylePagination(),
        },
      },
    },
    User: {
      fields: {
        agent_user_tags: relayStylePagination(),
        external_user_tags: relayStylePagination(),
      },
    },
    ShareableSearchLink: {
      fields: {
        users: relayStylePagination(),
      },
    },
  },
}

export default cachePolicies
